import {useForm} from 'react-hook-form'
import { Client_Event_Name, } from '@quiz-buzz/common'
import {page_header_style, Standard_Page_Layout, sub_header_style} from '../Components/Standard_Page_Layout'
import { Base_Props } from '@quiz-buzz/client-common'
import { style } from 'typestyle'
import { Button } from '../Components/Button'

type FormValues = {
	player_name: string
	game_code: string
}

export function Player_Registration(props: Base_Props) {
	const {send} = props
	const {
		register,
		handleSubmit,
		formState: {errors},
	} = useForm<FormValues>()
	const onSubmit = handleSubmit(data =>
		send({type: Client_Event_Name.register_player, detail: data}),
	)
	const cancel_registration = () => {
		window.location.hash = ''
		history.back()
	}
	let join_code = ''
	if (window.location.hash && window.location.hash.slice(1).length === 4) {
		join_code = window.location.hash.slice(1)
	}

	const header = (
		<>
			<div className={page_header_style}>Join</div>
			<div className={sub_header_style}>Join game, code: <span className="highlight_text">{join_code}</span></div>
		</>
	)

	const content = (
		<>
			<form
				id="register_player_form"
				className={register_player_form_style}
				name="register_player"
				onSubmit={onSubmit}>
				<span>
					<div className="form_row">
						<label htmlFor="player_reg_name">Name</label>
						<input
							id="player_reg_name"
							className={errors.player_name ? 'validation_warn' : ''}
							{...register('player_name', {required: true, maxLength: 20})}
							placeholder="your player name"
						/>
						{errors?.player_name && <p>{errors.player_name.message}</p>}
					</div>
					<input
						type="hidden"
						className={
							errors.game_code ? 'validation_warn uppercase' : 'uppercase'
						}
						id="player_reg_code"
						{...register('game_code', {required: true, maxLength: 20})}
						defaultValue={join_code}
						placeholder="game code"
					/>
				</span>
			</form>
		</>
	)

	const controls = (
		<div className='two_buttons'>
			<Button button_style="Danger" text="Cancel" onClick={cancel_registration} />
			<Button button_style="Primary" text="Join game" type="submit" form="register_player_form" />
		</div>
	)

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}

const register_player_form_style = style({
	margin: '0 auto'
})