import { Client_Event_Name, Host_Event_Name, } from '@quiz-buzz/common'
import {page_header_style, Standard_Page_Layout, sub_header_style} from '../Components/Standard_Page_Layout'
import { Base_Props, Client_State_Name } from '@quiz-buzz/client-common'
import { style } from 'typestyle'
import { header_over_form_style } from '../App/style/Headers'

export function Host_Unverified(props: Base_Props) {
	const {send} = props

	const send_cancel_game = () => send({type: Client_Event_Name.cancel_game})

	const move_next = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.target) {
			(
				(event.target as HTMLInputElement).nextElementSibling as HTMLElement
			)?.focus()
		}
		send_verification()
	}

	const send_verification = () => {

		const type = props.state_value === Client_State_Name.host_game_unverified ? Client_Event_Name.verify : Host_Event_Name.resume_game

		const d1 =
			document.querySelector<HTMLInputElement>('#verification_digit_1')
				?.value || ''
		const d2 =
			document.querySelector<HTMLInputElement>('#verification_digit_2')
				?.value || ''
		const d3 =
			document.querySelector<HTMLInputElement>('#verification_digit_3')
				?.value || ''
		const d4 =
			document.querySelector<HTMLInputElement>('#verification_digit_4')
				?.value || ''
		if (d1 !== '' && d2 !== '' && d3 !== '' && d4 !== '') {
			send({
				type,
				detail: {
					one_time_verification_code: d1 + d2 + d3 + d4,
					host_email: props.context.host_email
				},
			})
		}
	}

	const header = (
		<>
			<div className={page_header_style}>Verify game</div>
			<div className={sub_header_style}>check your email to verify the game</div>
		</>
	)

	const handleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => event.target.select()

	const content = (
		<>
			<div className={header_over_form_style}>
				<div className="h2">verification email sent to</div>
				<div className={email_pod_style}>{props.context.host_email}</div>
				<div className="h2">enter the 4 digit code here:</div>
			</div>
			
			<div className={verification_inputs_style}>
				<input
					onFocus={handleFocus}
					type="number"
					id="verification_digit_1"
					data-testId="verification_digit_1"
					className={single_digit_input_style}
					onKeyUp={move_next}></input>
				<input
					onFocus={handleFocus}
					type="number"
					id="verification_digit_2"
					data-testId="verification_digit_2"
					className={single_digit_input_style}
					onKeyUp={move_next}></input>
				<input
					onFocus={handleFocus}
					type="number"
					id="verification_digit_3"
					data-testId="verification_digit_3"
					className={single_digit_input_style}
					onKeyUp={move_next}></input>
				<input
					onFocus={handleFocus}
					type="number"
					id="verification_digit_4"
					data-testId="verification_digit_4"
					className={single_digit_input_style}
					onKeyUp={move_next}></input>
			</div>
			<div className="hidden">
				<input data-testid="game_code" value={props.context.game_code} />
			</div>
		</>
	)

	const controls = (
		<div className='two_buttons'>
			<button className="negative" onClick={send_cancel_game}>
				Cancel game
			</button>
		</div>
	)
	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}

const verification_inputs_style = style({
	display: 'flex',
	margin: '0 auto',
	flexDirection: 'row',
})

const single_digit_input_style = style({
	width: '1.8em',
	textAlign: 'center',
	fontSize: '1.6rem',
	appearance: 'textfield',
	'-webkit-appearance': 'textfield',
	padding: '0.35em',
	margin: '0.25em',
})

const email_pod_style = style({
	border: '1px solid white',
	padding: '0.2em',
	margin: 'auto',
})
