import React, { ReactElement, useState } from "react";
import { style } from "typestyle";

type Tabs_Props = {
    children: React.ReactNode
}

/**
 * A tabs component: 
```
<Tabs>
    <Tab title="TabA">
        content...
    </Tab>
    <Tab title="TabB">
        content...
    </Tab>
<Tabs>
 ```
 * Optionally also put a <Tab_Under_Header> inside the tab content to add a header under the tabs:
```
<Tabs>
    <Tab title="TabA">
        <Tab_Under_Header>some custom header content here</Tab_Under_Header>
        content...
    </Tab>
    <Tab title="TabB">
        content...
    </Tab>
<Tabs>
 ```


 */
export const Tabs: React.FC<Tabs_Props> = ({ children }) => {
    const [selectedTab, setSelectedTab] = useState(0)

    if (!children || !Array.isArray(children)) {
        return null
    }

    const child_tab_nodes = children.filter(item => React.isValidElement(item)).filter(item => item.type === Tab)

    return (
        <div>
            <div className={tab_button_row_style}>
                {child_tab_nodes.map((item, index) => {
                    const tab_node = item as ReactElement<Tab_Props>
                    
                    return (
                    <Tab_Title
                        selected={index === selectedTab}
                        key={index}
                        title={tab_node.props.title}
                        index={index}
                        setSelectedTab={setSelectedTab}
                    />)
                }
            )}
            </div>
            {children[selectedTab]}
        </div>
    )
}



type Tab_Title_Props = {
    selected: boolean
    title: string
    index: number
    setSelectedTab: (index: number) => void
}

const toggle_style = (style_enabled: boolean, className: string) => style_enabled ? className : ''

/**
 * An internal component for tab titles (the tabs on top)
 */
const Tab_Title: React.FC<Tab_Title_Props> = ({ title, selected, setSelectedTab, index }) => {
    return (
        <button className={`${tab_button_style} ${toggle_style(selected, tab_button_selected_style)}`} onClick={() => setSelectedTab(index)}>{title}</button>
    )
}




interface Tab_Props {
    title: string
    children: React.ReactNode
}

export function Tab(props: Tab_Props) {
    return (
        <div className={tab_content_style}>
            <div className={tab_under_inner_style}></div>
            {props.children}
        </div>
    )
}

interface Tab_Header_Props {
    children: React.ReactNode
}

export function Tab_Under_Header(props: Tab_Header_Props) {
    return (
        <div className={header_under_tabs_style}>
            {props.children}
        </div>
    )
}



const tab_button_row_style = style({
	width: '100%',
	display: 'flex',
})

export const header_under_tabs_style = style({
	display: 'flex',
	flexDirection: 'column',
	border: '0.2em solid #ad2d58',
	borderRadius: '0 0 0.4em 0.4em',
	textAlign: 'center',
	margin: '0',
	color: 'black',
	padding: '0.3em',
})

const tab_button_style = style({
    height: '2.3em',
	width: '50%',
    border: 'none',
    fontSize: '1.15em',
    fontWeight: 'bold',
	borderRadius: '0.5em 0.5em 0 0',
	margin: '0',
	color: '#bbb',
	backgroundColor: 'black',
    cursor: 'pointer',
})

const tab_button_selected_style = style({
	color: '#fff',
	backgroundImage: 'linear-gradient(#ff4e8c,#ad2d58)',
})

const tab_content_style = style({
	display: 'flex',
	flexDirection: 'column',
})

const tab_under_inner_style = style({
	height: '0.5em',
	width: '50%',
	backgroundColor: 'black',
	position: 'absolute',
	top: '0',
	right: '0',
	borderRadius: '0 0 0 .25em',
})