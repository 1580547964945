import { Host_Event_Name } from '@quiz-buzz/common'
import {page_header_style, Standard_Page_Layout, sub_header_style} from '../Components/Standard_Page_Layout'
import { Base_Props, Game_Results, get_game_results } from '@quiz-buzz/client-common'
import { Button } from '../Components/Button'

export function Host_Game_Finished(props: Base_Props) {
	const {send} = props
	const {teams_enabled} = props.context
	const send_close_game = () => send({type: Host_Event_Name.close_game})

	const results = get_game_results(props.context)

	const generate_results_content = (results: Game_Results) => {
		if (teams_enabled) {
			if (!results.team_winners || results.team_winners.length === 0) {
				return <div className="results_box_header">Game ended</div>
			}
			else if (results.is_draw) {
				return (
					<>
						<div className="results_box">
							<div className="results_box_header">It's a draw!</div>
							<div className="results_box_winners">
								Congratulations to{' '}
								<div className="winner_list">
									{results.team_winners?.map(team => team.name).join(', ')}
								</div>
							</div>
						</div>
					</>
				)
			} else {
				return (
					<>
						<div className="results_box">
							<div className="results_box_header">We have a winner!</div>
							<div className="results_box_winners">
								Congratulations to{' '}
								<div className="winner_list">
									{results.team_winners[0].name}
								</div>
							</div>
						</div>
					</>
				)
			}
		} else {
			if (!results.player_winners || results.player_winners.length === 0) {
				return <div className="results_box_header">Game ended</div>
			}
			else if (results.is_draw) {
				return (
					<>
						<div className="results_box">
							<div className="results_box_header">It's a draw!</div>
							<div className="results_box_winners">
								Congratulations to{' '}
								<div className="winner_list">
									{results.player_winners.map(player => player.name).join(', ')}
								</div>
							</div>
						</div>
					</>
				)
			} else {
				return (
					<>
						<div className="results_box">
							<div className="results_box_header">We have a winner!</div>
							<div className="results_box_winners">
								Congratulations to{' '}
								<div className="winner_list">
									{results.player_winners[0].name}
								</div>
							</div>
						</div>
					</>
				)
			}
		}
	}

	const header = (
		<>
			<div className={page_header_style}>Finished</div>
			<div className={sub_header_style}>
				congratulations to the winner!
			</div>
		</>
	)
	const content = generate_results_content(results)

	const controls = (
		<div className='two_buttons'>
			<Button button_style="Primary" text="Close game" onClick={send_close_game} />
		</div>
	)
	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
