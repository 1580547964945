
export const Colours = {
    primary_highlight: '#ffa14e',
    // primary_highlight_gradient_bright: '#ffa14e',
    //primary_highlight_gradient_dark: '#915724',

    primary_highlight_gradient_bright: '#2da7ad',
    primary_highlight_gradient_dark: '#157479',


    neon_highlight: '#ff4e8c',
    neon_radial_gradient: 'radial-gradient(#ff4e8c, #c53c6c)',
}
