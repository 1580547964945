import { Client_Event_Name, Host_Event_Name, } from '@quiz-buzz/common'
import {useForm} from 'react-hook-form'
import {page_header_style, Standard_Page_Layout, sub_header_style} from '../Components/Standard_Page_Layout'
import { Help_Tips, show_help_tip } from '../Help_Tips/Help_tips'
import { useEffect } from 'react'
import { Base_Props } from '@quiz-buzz/client-common'
import { style } from 'typestyle'
import { Button } from '../Components/Button'
import { header_over_form_style } from '../App/style/Headers'

type FormValues = {
	host_nickname: string
}

export function Host_Registration(props: Base_Props) {

	const {send} = props
	const { is_trial } = props.context
	const {
		register,
		handleSubmit,
		formState: {errors}
	} = useForm<FormValues>({
		defaultValues: {
		}
	})

	const onSubmit = handleSubmit(data =>
		send({ type: Host_Event_Name.register_trial_host, detail: { ...data, is_trial }}),
	)

	const header = (
		<>
			<div className={page_header_style}>Create game</div>
			<div className={sub_header_style}>let&apos;s get you started</div>
		</>
	)
	const content = (
		<>
			<div className={header_over_form_style}>
				<div className="h2"><label htmlFor="host_nickname">Please enter your name</label></div>
			</div>
			<form id="host_reg_form" className={host_reg_form_style} onSubmit={onSubmit}>
				<input
					id="host_nickname"
					type="text"
					data-help-tip="intro"
					className={'big_input ' + (errors.host_nickname ? 'validation_warn' : '')}
					{...register('host_nickname', {
						required: {
							value: true,
							message: 'you need a name!',
						},
						maxLength: {value: 100, message: 'too long'},
						minLength: {value: 3, message: 'too short'},
					})}
					placeholder="nickname"
				/>
				<div className="validation_error">
					{errors.host_nickname?.message}
				</div>
			</form>
		</>
	)

	const controls = (
		<div className="two_buttons">
			<Button text="Cancel" button_style="Danger" onClick={() => send({ type: Client_Event_Name.navigate_back })} />
			<Button text="Start setup" button_style="Primary" type="submit" form="host_reg_form" />
		</div>
	)
	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}

const host_reg_form_style = style({
	margin: '0 auto',
})

