import { Client_Config_Autotest } from "./Client_Config.autotest";
import { Client_Config_Localdev } from "./Client_Config.localdev";
import { Client_Config_Production } from "./Client_Config.production";
import { Client_Environment_Config } from "./Client_Environment_Config_Type";

export const Environments: {[index: string]: Client_Environment_Config} = {
	localdev: Client_Config_Localdev,
    autotest: Client_Config_Autotest,
    production: Client_Config_Production,
}

let env: Client_Environment_Config

export function set_environment(env_name: string) {
    env = Environments[env_name]
}

export function get_environment() {
    return env
}