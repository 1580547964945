import { Base_Props } from "@quiz-buzz/client-common";
import { Host_Event_Name } from "@quiz-buzz/common";
import { style } from "typestyle";


export function Host_Player_Table(props: Base_Props) {
    const {send} = props

    const {
		players,
        teams,
		teams_enabled,
	} = props.context

    const remove_player = (player_name: string) =>
        send({type: Host_Event_Name.remove_player, detail: {player_name}})

    // generate a map of which teams players are in:
	// TODO: move this to state machine and use it for this and the all_players_are_in_teams guard
	const player_teams: {[index: string]: string} = {}
	teams.list.map(team =>
		team.members.list.map(player => (player_teams[player.name] = team.name)),
	)

    return (
        <table data-testid="host_player_setup_table" className={host_player_list_style}>
            <thead>
                <tr>
                    <th className={th_style}>Player</th>
                    {teams_enabled ? <th className={th_style}>Team</th> : undefined}
                    <th className={th_style}>Active</th>
                    <th className={th_style}></th>
                </tr>
            </thead>
            {players.list.length === 0 && (
                <tr>
                    <td colSpan={3}>Players will appear here when they join..</td>
                </tr>
            )}
            <tbody>
                {players.list.map(player => (
                    <tr>
                        <td>
                            <div
                                className="player_item"
                                data-player-item={player.name}>
                                {player.name}
                            </div>
                        </td>
                        {teams_enabled ? <td className={team_name_cell_style}>{player_teams[player.name] || '-'}</td> : undefined}
                        <td>
                            <div
                                className={
                                    player.connected
                                        ? socket_connected_style
                                        : socket_disconnected_style
                                }></div>
                        </td>
                        <td>
                            <button className={remove_player_style} onClick={() => remove_player(player.name)}>
                                X
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

const host_player_list_style = style({
	tableLayout: 'fixed',
	width: '100%',
	borderCollapse: 'collapse',
	marginBottom: '.5em',
	border: '1px solid white',
})

const th_style = style({
	textAlign: 'left',
    color: 'white',
    backgroundColor: '#222',
})

const team_name_cell_style = style({
    overflow: 'hidden',
    textOverflow: 'ellipsis'
})

const remove_player_style = style({
	backgroundColor: '#ff4e8c',
    width: '100%',
    border: 'none',
})

const socket_connected_style = style({
	backgroundColor: '#ff4e8c',
    height: '1.5em',
    width: '1.5em',
    borderRadius: '0.75em',
})

const socket_disconnected_style = style({
	backgroundColor: 'black',
    height: '1.5em',
    width: '1.5em',
    borderRadius: '0.75em',
})