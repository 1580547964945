import { Player, Team } from '@quiz-buzz/common'
import { PlayerContext } from './player_machine'

export interface Game_Results {
	teams_enabled: boolean
	team_winners: Team[]
	player_winners: Player[]
	is_draw: boolean
}

export function get_game_results(context: PlayerContext): Game_Results {
	const {teams, players, teams_enabled} = context

	let team_winners: Team[] = []
	let player_winners: Player[] = []
	let is_draw = false

	if (teams_enabled) {
		let highest_score = -10000000
		teams.list.map(team => {
			if (team.score > highest_score) {
				highest_score = team.score
			}
		})
		team_winners = teams.list.filter(team => team.score === highest_score)
		if (team_winners.length > 1) {
			is_draw = true
		}
	} else {
		let highest_score = -10000000
		players.list.map(player => {
			if (player.score > highest_score) {
				highest_score = player.score
			}
		})
		player_winners = players.list.filter(
			player => player.score === highest_score,
		)
		if (player_winners.length > 1) {
			is_draw = true
		}
	}

	return {
		teams_enabled,
		team_winners,
		player_winners,
		is_draw,
	}
}
