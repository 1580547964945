import { Base_Props } from "@quiz-buzz/client-common"
import { Host_Event_Name } from "@quiz-buzz/common"
import { style } from "typestyle"


export const Host_In_Game_Who_Buzzed_Section = (props: Base_Props) => {
    
    const { send, context } = props
    const send_correct = () => send({type: Host_Event_Name.answer_is_correct, detail: { score_diff: 10 }})
    const send_incorrect = () => send({type: Host_Event_Name.answer_is_incorrect, detail: { score_diff: -5 }})


    const { 
        whos_buzzer_is_active, 
    } = context

    return (<div>
        <div className={buzzed_section_style}>
            <button
                className={buzzed_section_button_style}
                disabled={!whos_buzzer_is_active}
                onClick={send_incorrect}>
                <span className={text_small_style}>Incorrect</span>
            </button>

            <div className={who_buzzed_frame_style}>
                <div
                    data-testId="who_buzzed"
                    className={who_buzzed_style + ' ' + (!!whos_buzzer_is_active ? buzzed_label_is_active_style : '')}>
                    <span className={text_small_style}>Buzzed: </span><br /><span className={text_style_large}>{whos_buzzer_is_active ? whos_buzzer_is_active : 'no buzzer active'}</span>
                </div>
            </div>
            <button className={buzzed_section_button_style} disabled={!whos_buzzer_is_active} onClick={send_correct}>
                <span className={text_small_style}>Correct</span>
            </button>
        </div>
    </div>)

}

const who_buzzed_frame_style = style({
	position: 'relative',
	padding: '0 0.2em',
	margin: 'auto',
	display: 'flex',
	flex: '1',
    flexDirection: 'column',
    height: '2.3em',
})

const text_small_style = style({
    fontSize: '0.75em'
})

const text_style_large = style({
    fontSize: '1.2em',
    lineHeight: '0.9em',
})


const buzzed_section_style = style({
	display: 'flex',
    height: '2.3em',
    margin: '0.2em 0',
})

const buzzed_section_button_style = style({
	padding: '0.3em 0.2em',
	minWidth: '4em',
	height: 'auto'
})

const who_buzzed_style = style({
	backgroundColor: 'grey',
	borderRadius: '0.5em',
	width: '100%',
	height: '2.3em',
	textAlign: 'center',
    overflow: 'hidden',
})

const buzzed_label_is_active_style = style({
	backgroundColor: '#ff4e8c',
})
