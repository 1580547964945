import './Connection_Closed.css'
import {Base_Props, Websocket_Event_Names} from '@quiz-buzz/client-common'
import {Client_Event_Name} from '@quiz-buzz/common'
import { page_header_style, Standard_Page_Layout, sub_header_style } from '../Components/Standard_Page_Layout'
import { Button } from '../Components/Button'

export function Connection_Closed(props: Base_Props) {
	const {game_code} = props.context

	const {send, context} = props

	const retry_connection = () =>
		send({type: Websocket_Event_Names.connect_websocket})
	const reset_game = () => send({type: Client_Event_Name.reset_game})

	const header_section = <>
		<header className={page_header_style}>Problem!</header>
		{context.game_code ? <header className={sub_header_style}>game: {game_code}</header> : <></>}
	</>

	const content_section = <>
		<div className="two_buttons">
		{context.game_code ? (
			<Button text="Reconnect" button_style="Primary" onClick={retry_connection} />
		) : (
			<Button text="Back to home" button_style="Secondary" onClick={reset_game} />
		)}
		</div>
	</>

	return (
		<Standard_Page_Layout
			header={header_section}
			content={content_section}
			controls={<></>} />
	)
}
