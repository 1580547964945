import { style } from 'typestyle'

type Standard_Page_Layout_Props = {
	header: JSX.Element
	content: JSX.Element
	controls: JSX.Element
	no_content_margin?: boolean
}

export function Standard_Page_Layout(props: Standard_Page_Layout_Props) {
	const {header, content, controls, no_content_margin} = props
	
	const content_margin_style = {margin: '0.35rem'}

	return (
		<div className={standard_page_layout_style}>
			<div className={header_section_style}>{header}</div>
			<section style={no_content_margin ? {} : content_margin_style} className={page_content_area_style}>{content}</section>
			<div className={controls_section_style}>{controls}</div>
		</div>
	)
}

const standard_page_layout_style = style({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	background: 'radial-gradient(circle at 50% 50%,#fed23f,#ca9e22)'
})

const header_section_style = style({
	display: 'flex',
	flexDirection: 'column',
	padding: '1em 0 0.6em 0'
})

export const page_header_style = style({
	fontSize: '2em',
	textAlign: 'center',
	margin: 'auto',
	color: 'black',
	borderRadius: '0.4em 0.4em 0 0',
	padding: '0 0.3em 0.3em',
})

export const sub_header_style = style({
	margin: 'auto',
	color: 'black',
	padding: '0 0.3em 0.3em',
	textAlign: 'center',
})

const page_content_area_style = style({
	display: 'flex',
	flexDirection: 'column',
	flex: '1',
	height: '100%',
	overflow: 'auto',
})

const controls_section_style = style({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-evenly',
	padding: '0.4rem 0 1.5rem 0',
	backgroundColor: 'white',
	overflow: 'auto',
	borderRadius: '1.4em 1.4em 0 0',
	boxShadow: '#9e7f06 0em 0em 2em',
})