import { Base_Props } from '@quiz-buzz/client-common'
import { CUSTOM_QUESTIONS, Host_Event_Name, Subject_Listing } from '@quiz-buzz/common'
import { Button } from '../Components/Button'
import { Host_In_Game_Who_Buzzed_Section } from './Host_In_Game_Who_Buzzed_Section'
import { style } from 'typestyle'
import { Tab_Under_Header } from '../Components/Tabs'


export function Host_In_Game_Questions_Content(props: Base_Props) {
    const { send, context } = props

    const { 
        chosen_trivia_topic, 
        trivia_topics, 
        round_num,
        question_num,
        question_text,
        answer_text, 
    } = context

	const get_trivia_topics = () => send({ type: Host_Event_Name.get_trivia_topics})
	const use_my_own_questions = () => send({ type: Host_Event_Name.choose_trivia_topic, detail: { subject: {field: CUSTOM_QUESTIONS, name: CUSTOM_QUESTIONS} }})
	const choose_topic = (subj: Subject_Listing) => send({ type: Host_Event_Name.choose_trivia_topic, detail: { subject: subj }})
	const skip_question = () => send({type: Host_Event_Name.skip_question})

	const generate_trivia_topic_list = () => {
		if (trivia_topics?.fields.length) {
			return <div className={trivia_topics_list_style}>
				{trivia_topics.fields.map(field => (
				<div className={trivia_field_listing_style}>
					<div className={trivia_field_name_style}>{field.name}</div>
					<ul className={trivia_field_listing_ul_style}>
						{field.subjects.map(subj => <li className={trivia_field_listing_li_style} onClick={() => choose_topic(subj)}>{subj.name}</li>)}
					</ul>
                    <hr />
				</div>
			))}
			</div>
		}
		else {
			return <div className={round_number_banner_style}>
				<div className={round_number_header_style}>Round {round_num}!</div>
				<div className={round_number_subheader_style}>setup</div>
			</div>
		}
	}

    const get_topic_name_display = () => {
		if (!chosen_trivia_topic) {
			return ''
		}
		else if (chosen_trivia_topic.field === CUSTOM_QUESTIONS) {
			return 'Custom round'
		}
		return `${chosen_trivia_topic.field} - ${chosen_trivia_topic.name}`
	}

    return !chosen_trivia_topic ?
        <>
            <Tab_Under_Header>
                <div className='two_buttons' data-help-tip='game_start'>
                    <Button text="Choose topic" button_style="Primary" onClick={get_trivia_topics} />
                    <Button text="Use my own" button_style="Secondary" onClick={use_my_own_questions} />
                </div>
			</Tab_Under_Header>
            <div>
                {generate_trivia_topic_list()}
            </div>
        </> :
        <>
            <Host_In_Game_Who_Buzzed_Section context={props.context} send={send} state_value={props.state_value} />
            <div>
                <div className={question_display_style}>
                    <div className={chosen_trivia_topic_style}>Topic: {get_topic_name_display()}</div>
                    <div className={question_header_style}><span className={QA_Icon_style}>Q{question_num}</span>{question_text}</div>
                    <div className={answer_header_style}><span className={QA_Icon_style}>A{question_num}</span>{answer_text} <button className="inline tiny" onClick={skip_question}>Skip it</button></div>
                </div>
            </div>
        </>
}


const trivia_field_listing_style = style({
	width: '100%',
})

const trivia_field_listing_ul_style = style({
	padding: '0',
})

const trivia_field_listing_li_style = style({
	color: 'black',
  	listStyle: 'square',
  	marginLeft: '1em',
})

const trivia_topics_list_style = style({
	display: 'flex',
	flexDirection: 'column',
})

const trivia_field_name_style = style({
	fontSize: '1.1rem',
	padding: '0.2em',
	color: '#15104b',
	marginRight: '0.1em',
})

const round_number_banner_style = style({
	margin: '5em auto',
	transform: 'rotate(-8deg)',
	textAlign: 'center',
	fontFamily: '"Titan One", sans-serif',
})

const round_number_header_style = style({
	fontSize: '3rem',
})

const round_number_subheader_style = style({
	fontSize: '1.5rem',
})

const question_display_style = style({
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid white',
    marginBottom: '0.2em',
})

const QA_Icon_style = style({
    fontFamily: '"Titan One"',
    fontSize: '1.3em',
    marginRight: '0.5em',
})

const chosen_trivia_topic_style = style({
    padding: '0.3em 0',
})

const question_header_style = style({
    backgroundColor: 'white',
    border: '1px solid white',
    padding: '0.6em',
	
})

const answer_header_style = style({
    backgroundColor: 'black',
	color: 'white',
    border: '1px solid white',
    padding: '0.6em',
})