import {page_header_style, Standard_Page_Layout, sub_header_style} from '../Components/Standard_Page_Layout'
import { Base_Props } from '@quiz-buzz/client-common'
import { style } from 'typestyle'
import { Splash_Main_Content } from './Splash_Main_Content'
import { useState } from 'react'

export function Splash_Page(props: Base_Props) {

	const [show_more_options, set_show_more_options] = useState(false)

	const header = (
		<>
			<div className={page_header_style}>Quiz Buzz</div>
			<div className={sub_header_style}>Run your own game show with friends!</div>
		</>
	)

	const content = (
		<Splash_Main_Content />
	)

	const controls = <>
		<div
			className={splash_button_style}
			onClick={() => props.send({type: 'start_host_trial_registration'})}>
				Host a game
		</div>
		<div
			className={splash_button_style}
			onClick={() => {set_show_more_options(!show_more_options)}}>
				More options
		</div>
		{show_more_options ? <div className={more_options_style}>
			<div><a target="_blank" href="https://youtube.com">How to host (video)</a></div>
		</div> : null}

		
	</>

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
			no_content_margin={true}
		/>
	)
}


const splash_button_style = style({
	display: 'flex',
	fontSize: '1.5em',
    borderRadius: '1em',
    margin: '.3rem 0 0 1.5em',
    color: 'black',
    padding: '.3em'
})

const more_options_style = style({
	display: 'flex',
	flexDirection: 'column',
	margin: '.3rem 0 0 5em',
})





