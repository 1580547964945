import {useForm} from 'react-hook-form'
import { Client_Event_Name, Host_Event_Name } from '@quiz-buzz/common'
import 'react-toggle/style.css'
import {page_header_style, Standard_Page_Layout} from '../Components/Standard_Page_Layout'
import toast from 'react-hot-toast'
import Toggle from 'react-toggle'
import { Help_Tips } from '../Help_Tips/Help_tips'
import { Base_Props } from '@quiz-buzz/client-common'
import { style } from 'typestyle'
import { Tab, Tab_Under_Header, Tabs } from '../Components/Tabs'
import { Team_And_Members_Box } from '../Components/Team_And_Members_Box'
import { Button } from '../Components/Button'
import { Icon_Clipboard } from '../Components/Icons/Icon_Clipboard'
import { Host_Player_Table } from './Host_Player_Table'

type Add_Team_Form_Values = {
	team_name: string
}

export function Host_Game_Setup(props: Base_Props) {
	const {
		game_code,
		teams,
		teams_enabled,
		
	} = props.context

	const {send, state_value} = props
	const {
		register,
		resetField,
		handleSubmit,
		formState: {errors},
	} = useForm<Add_Team_Form_Values>()
	const onSubmit = handleSubmit(data => {
		send({type: Client_Event_Name.add_team, detail: data})
		resetField('team_name')
	})

	const toggle_teams = () => {
		send({type: Host_Event_Name.set_teams_enabled, detail: { teams_enabled: !teams_enabled}})
	}

	const start_game = () => send({type: Host_Event_Name.start_game})

	const save_and_exit = () => send({type: Host_Event_Name.save_and_exit})
	const link =
		window.location.protocol +
		'//' +
		window.location.host +
		`#${game_code.toUpperCase()}`

	const copy_link_to_clipboard = () => {
		navigator.clipboard.writeText(link).then(() => {
			toast.success(`link copied to clipboard, send it to your players to join`, {
				position: 'top-center',
			})
		}).catch(e => {
			toast.error(`copy to clipboard failed, ${e}`)
		})
	}

	const send_cancel_game = () => send({type: Client_Event_Name.cancel_game})

	const remove_team = (team_name: string) =>
        send({type: Host_Event_Name.remove_team, detail: {team_name}})

	const generate_teams_list = () => {
		if (!teams_enabled) return <></>
		return teams.list.map(team => <Team_And_Members_Box is_host={true} team={team} primary_action={() => remove_team(team.name)} />)
	}

	const header = (
		<>
			<header className={page_header_style}>
				Game setup
			</header>
			<div className="hidden">
				<input data-testid="join_game_link" value={link} />
				<input data-testid="game_code" value={game_code} />
			</div>
		</>
	)

	const content = (
		<>
			<Tabs>
				<Tab title="Teams">
					<Tab_Under_Header>
						<form onSubmit={onSubmit}>
							<div className={add_team_section_style}>
								<div className={teams_toggle_style}>
									<div>
									<label className={label_small_style} data-testId="teams_toggle" htmlFor="teams_toggle">Teams</label>
									<Toggle id="teams_toggle" className='pink-toggle' onChange={toggle_teams} defaultChecked={teams_enabled} />
									</div>
								</div>
								{ teams_enabled ? <>
								<div className={add_team_input_section_style}>
									<div
										className={
											errors?.team_name ? 'validation_warn_attach' : 'hidden'
										}>
										{errors.team_name?.message}
									</div>
									
									<input
										className={team_name_input_style + (errors?.team_name ? ' validation_warn' : '')}
										{...register('team_name', 
											{
												required: {
													value: true,
													message: 'teams need names',
												},
												maxLength: {value: 20, message: 'too long'},
												minLength: {value: 3, message: 'too short'},
											}
										)}
										placeholder="add a team name"
									/>
									<button className="input_attach main_action_style" type="submit">+</button>
								</div>
								</> : <span className={teams_disabled_message_style}>Players play individually</span>}
							</div>
						</form>
					</Tab_Under_Header>
					{generate_teams_list()}
				</Tab>
				<Tab title="Players">
					<Tab_Under_Header>
						<Button text="Invite players" button_style="Primary" icon={Icon_Clipboard} onClick={copy_link_to_clipboard}></Button>
					</Tab_Under_Header>
					<Host_Player_Table context={props.context} send={props.send} state_value={state_value} />
				</Tab>
			</Tabs>

			<Help_Tips id="teams_setup">
				<div>
					<h2>Setting up your game</h2>
					<p>
						Add and remove teams here or turn teams off altogether if you want your players to play individually. 
					</p>
					<p>
						At any time during a game you can pause and change these settings so feel free
						to mess around with these settings now.
					</p>
					<p>
						When you're happy click on the players tab to invite people.
					</p>
				</div>
			</Help_Tips>
			<Help_Tips id="players_setup">
				<div>
					<h2>Inviting players</h2>
					<p>
						This button copy/pastes a link into your clipboard, you need to send it to your players
						and when they open it they will just need to enter their player name and click join.
					</p>
					<p>
						Your players will appear in the list when they join, the green dot means they're connected,
						or red if they disconnect. You can remove players with the X.
					</p>
					<p>
						Once you're in the game you can pause it at any time to add or remove players.
					</p>
				</div>
			</Help_Tips>
		</>
	)

	const controls = (
		<>
			<div className="two_buttons">
				<Button text="Start game" button_style="Primary" onClick={start_game} />
			</div>
			<div className='two_buttons'>
				<Button text="Cancel game" button_style="Danger" onClick={send_cancel_game} />
				<Button text="Save and exit" button_style="Secondary" onClick={save_and_exit} />
			</div>
			
		</>
	)

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}

const team_name_input_style = style({
	maxWidth: '10em',
	height: '2.2em',
	marginRight: '0',
})

const add_team_section_style = style({
	display: 'flex',
	width: '100%',
	justifyContent: 'space-between',
})

const add_team_input_section_style = style({
	display: 'flex',
})

const label_small_style = style({
	fontSize: '0.9em',
})

const teams_toggle_style = style({
	width: '3em',
})

const teams_disabled_message_style = style({
	margin: 'auto',
})


