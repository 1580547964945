import { Client_Event_Name, } from '@quiz-buzz/common'
import {page_header_style, Standard_Page_Layout, sub_header_style} from '../Components/Standard_Page_Layout'
import { Base_Props } from '@quiz-buzz/client-common'
import { style } from 'typestyle'
import { Team_And_Members_Box } from '../Components/Team_And_Members_Box'
import { Button } from '../Components/Button'

export function Player_Game_Setup(props: Base_Props) {
	const {
		send,
		context: {
			teams,
			teams_enabled,
			players,
			player_name,
			player_secret,
		},
	} = props
	
	const join_team = (team_name: string) => {
		send({
			type: Client_Event_Name.join_team,
			detail: {team_name, player_name, player_secret},
		})
		return false
	}

	const buzz = () =>
		send({
			type: Client_Event_Name.test_buzz,
			detail: {player_name, player_secret},
		})
	const quit = () =>
		send({
			type: Client_Event_Name.quit,
			detail: {player_name, player_secret},
		})

	const header = (
		<>
			<div className={page_header_style}>
				{teams_enabled ? 'Pick a team' : 'Pre game setup'}
			</div>
			<div className={sub_header_style}>
				player: {player_name}
			</div>
		</>
	)

	const content = (
		<>
			<div className={game_setup_content_style}>
				{(teams_enabled && teams.list.length === 0) ?
					<>
						<div>Waiting for host to add some teams..</div>
					</> : <></>
				}
				{(teams_enabled && teams.list.length >= 0) ?
					<div className={teams_table_style}>
						{teams.list.map(team => (
							<Team_And_Members_Box team={team} is_host={false} primary_action={() => join_team(team.name)} />
						))}
					</div>
					: <></>
				}
				{!teams_enabled && (
					<>
						<h2>Players</h2>
						{players.list.map(player => (
							<div>
								<div className={player_item_style} data-player-item={player.name}>
									{player.name}
								</div>
								<div
									className={
										player.connected
											? 'socket_connected'
											: 'socket_disconnected'
									}></div>
							</div>
						))}
					</>
				)}
			</div>
		</>
	)

	const controls = (
		<div className='two_buttons'>
			<Button text="Quit game" button_style="Danger" onClick={quit} />
			<Button text="Test buzzer" button_style="Primary" onClick={buzz} />
		</div>
	)

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}

const game_setup_content_style = style({
	overflow: 'auto',
})

export const teams_table_style = style({
	display: 'flex',
	flexDirection: 'column',
})

const player_item_style = style({
	transition: 'backgroundColor 0.2s',
})
