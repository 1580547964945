import {assign, sendTo, setup, type AnyEventObject} from 'xstate'
import {Websocket_Event_Names, websocketCallback} from './websockets_machine'
import {
	Map_And_List,
	Player, 
	Team,
	Client_Event_Name,
	Host_Event_Name,
	Server_Event_Name,
	Server_State_Name,
	Game_Update_Event,
	Host_Resume_Event,
	Host_Update_Event,
	Subject_Listing, 
	Trivia_Fields,
} from '@quiz-buzz/common'
import {ReactElement} from 'react'
import { all_players_are_in_teams } from './player_machine_state_guards'

export interface PlayerContext {
	player_name: string
	is_trial: boolean
	game_code: string
	player_secret: string
	host_secret: string
	host_email: string
	host_nickname: string
	remaining_game_count: number | undefined
	players: Map_And_List<Player>
	teams: Map_And_List<Team>
	whos_buzzer_is_active: string | undefined
	teams_enabled: boolean
	trivia_topics?: Trivia_Fields
	chosen_trivia_topic?: Subject_Listing
	round_num: number
	question_num: number
	question_text: string
	answer_text: string
	modal_header?: string
	modal_content?: ReactElement
	modal_confirm_action?: () => void
	modal_cancel_action?: () => void
}

export interface EventReceiver {
	send: (event: Event) => void
}

interface Action_Props {
	event: AnyEventObject,
	context: PlayerContext
}

const send_host_action = sendTo('websocketCallback', ({event, context}: Action_Props) =>
	add_standard_host_message_fields(event, context),
)

// This is a shorthand action object that sends a message through as a host message to the server
const send_host_message = {
	actions: send_host_action
}

export enum Client_State_Name {
	splash = 'splash',
	registering_player = 'registering_player',
	connecting_player = 'connecting_player',
	player_game_setup = 'player_game_setup',
	player_in_game = 'player_in_game',
	player_finished = 'player_finished',
	connecting_host = 'connecting_host',
	registering_host = 'registering_host',
	host_game_unverified = 'host_game_unverified',
	host_resume_unverified = 'host_resume_unverified',
	ran_out_of_games = 'ran_out_of_games',
	host_game_setup = 'host_game_setup',
	host_in_game = 'host_in_game',
	host_finished = 'host_finished',
	connection_closed = 'connection_closed',
	reconnected = 'reconnected',
	restore_state = 'restore_state',
}

function add_standard_player_message_fields(
	event: AnyEventObject,
	context: PlayerContext,
) {
	return {
		type: event.type,
		detail: {
			...event.detail,
			player_name: context.player_name,
			player_secret: context.player_secret,
			game_code: context.game_code,
		},
	}
}

function add_standard_host_message_fields(
	event: AnyEventObject,
	context: PlayerContext,
) {
	return {
		type: event.type,
		detail: {
			...event.detail,
			host_secret: context.host_secret,
			host_email: context.host_email,
			game_code: context.game_code,
		},
	}
}

export interface Notifier {
	info(message: string): void
	error(message: string): void
}

export type Client_State_Machine = ReturnType<typeof create_player_machine>

let player_machine: Client_State_Machine | undefined = undefined

export const get_player_machine = (toast: Notifier) => {
	if (!player_machine) {
		player_machine = create_player_machine(toast)
	}
	return player_machine
}

const create_player_machine = (toast: Notifier) => {

	return setup({
		types: {
			context: {} as PlayerContext,
		},
		actors: {
			websocketCallback,
		},
		actions: {
			log_error: ({event, context}) => {
				const error = {
					error: 'event_error',
					detail: {
						event: event,
						context,
					},
				}
				console.log(error)
			},
			event_error: ({event, self}) => {
				console.log(event)
				if (event.detail?.error_message) {
					toast.error(
						event.detail.error_message,
					)
				}
				else {
					toast.error(
						`event [${event.type}] from source: [${
							event.detail?.source
						}] not valid in state [${self.getSnapshot().value}]`,
					)
				}
				
			},
			publish_error: ({event}) => {
				toast.error(`${event.type}, ${event.detail?.message}`)
			},
			set_trial_mode: assign({
				is_trial: () => true,
			}),
			set_host_nickname: assign({
				host_nickname: ({event}) => event.detail.host_nickname,
			}),
			set_host_email: assign({
				host_email: ({event}) => event.detail.host_email,
			}),
			init_host: assign({
				host_secret: ({event}) => event.detail.host_secret,
				host_email: ({event}) => event.detail.host_email,
				teams_enabled: ({event}) => event.detail.teams_enabled,
				game_code: ({event}) => event.detail.game_code,
			}),
			notify_verification_code_incorrect: () => {
				toast.error('verification code incorrect, please check and try again')
			},
			update_game_state: assign({
				players: ({event}) => (event as Game_Update_Event).detail.players,
				teams: ({event}) => (event as Game_Update_Event).detail.teams,
				whos_buzzer_is_active: ({event}) =>
					(event as Game_Update_Event).detail.whos_buzzer_is_active,
				round_num: ({event}) =>
					(event as Game_Update_Event).detail.current_round,
				question_num: ({event}) =>
					(event as Game_Update_Event).detail.current_question,
				teams_enabled: ({event}) =>
					(event as Game_Update_Event).detail.teams_enabled,
			}),
			update_host_state: assign({
				players: ({event}) => (event as Host_Update_Event).detail.players,
				teams: ({event}) => (event as Host_Update_Event).detail.teams,
				remaining_game_count: ({event}) =>
					(event as Game_Update_Event).detail.remaining_game_count,
				whos_buzzer_is_active: ({event}) =>
					(event as Host_Update_Event).detail.whos_buzzer_is_active,
				round_num: ({event}) =>
					(event as Host_Update_Event).detail.current_round,
				question_num: ({event}) =>
					(event as Host_Update_Event).detail.current_question,
				teams_enabled: ({event}) =>
					(event as Game_Update_Event).detail.teams_enabled,
				chosen_trivia_topic:  ({event}) =>
					(event as Game_Update_Event).detail.chosen_trivia_topic,
				question_text: ({event}) =>
					(event as Game_Update_Event).detail.question_text,
				answer_text: ({event}) =>
					(event as Game_Update_Event).detail.answer_text,
			}),
			update_host_resume_state: assign({
				host_email: ({event}) => (event as Host_Resume_Event).detail.host_email,
				host_secret: ({event}) => (event as Host_Resume_Event).detail.host_secret,
				game_code: ({event}) => (event as Host_Resume_Event).detail.game_code,
			}),
			update_test_buzzer: ({event}) => {
				// Bypass the react render cycle for maximum instant user responsiveness
				const player_item = document.querySelector(
					`[data-player-item="${event.detail.player_name}"]`,
				)
				if (player_item) {
					player_item.classList.add('buzzed')
					setTimeout(() => player_item.classList.remove('buzzed'), 500)
				}
			},
			update_trivia_topics: assign({
				trivia_topics: ({ event }) => event.detail
			}),
			clear_trivia_topics: assign({
				trivia_topics: () => ({ fields: [] })
			}),
			reset_chosen_trivia_topic: assign({
				chosen_trivia_topic: () => undefined
			}),
			player_registered: assign({
				player_name: ({event}) => event.detail.player_name,
				player_secret: ({event}) => event.detail.player_secret,
				game_code: ({event}) => event.detail.game_code,
			}),
			handle_player_buzzed: assign({
				whos_buzzer_is_active: ({event}) => event.detail.whos_buzzer_is_active,
			}),
			reset_context: assign({
				player_name: () => '',
				game_code: () => '',
				player_secret: () => '',
				host_email: () => '',
				host_nickname: () => '',
				host_secret: () => '',
				players: () => ({
					map: {},
					list: [],
				}),
				teams: () => ({
					map: {},
					list: [],
				}),
				round_num: () => 0,
				question_num: () => 0,
				is_trial: false,
				trivia_topics: undefined,
				chosen_trivia_topic: undefined,
				whos_buzzer_is_active: () => undefined,
				question_text: () => '',
				answer_text: () => '',
			}),
			clear_game_code_hash: () => {
				window.location.hash = ''
			},
			notify_feedback_received: () => {
				toast.info('feedback received, thanks!')
			}
		},
		guards: {
			has_player_details: ({event}) =>
				!!event.detail.player_name && !!event.detail.game_code,
			has_register_host_details: ({event}) =>
				(!!event.detail.host_email),
			has_register_trial_host_details: ({event}) =>
				(!!event.detail.host_nickname),
			is_valid_team: ({event, context}) =>
				event.detail.team_name && !context.teams.map[event.detail.team_name],
			all_players_are_in_teams: all_players_are_in_teams,
			no_host_or_player_secret: ({context}) =>
				!context.player_secret && !context.host_secret,
			restore_host_game_unverified: ({event, context}) =>
				event.detail?.state === Server_State_Name.unverified &&
				!!context.host_secret,
			restore_host_ran_out_of_games: ({event, context}) =>
				event.detail?.state === Server_State_Name.ran_out_of_games &&
				!!context.host_secret,			
			restore_host_game_setup: ({event, context}) =>
				event.detail?.state === Server_State_Name.pre_game_setup &&
				!!context.host_secret,
			restore_player_game_setup: ({event, context}) =>
				event.detail?.state === Server_State_Name.pre_game_setup &&
				!!context.player_secret,
			restore_player_in_game: ({event, context}) =>
				event.detail?.state === Server_State_Name.in_play &&
				!!context.player_secret,
			restore_host_in_game: ({event, context}) =>
				event.detail?.state === Server_State_Name.in_play &&
				!!context.host_secret,
			restore_host_finished: ({event, context}) =>
				event.detail?.state === Server_State_Name.finished &&
				!!context.host_secret,
			restore_player_finished: ({event, context}) =>
				event.detail?.state === Server_State_Name.finished &&
				!!context.player_secret,
		},
	}).createMachine({
		initial: Client_State_Name.splash,
		invoke: {
			id: 'websocketCallback',
			src: 'websocketCallback',
		},
		context: {
			player_name: '',
			game_code: '',
			is_trial: false,
			player_secret: '',
			host_secret: '',
			host_email: '',
			host_nickname: '',
			remaining_game_count: 0,
			players: {
				map: {},
				list: [],
			},
			teams: {
				map: {},
				list: [],
			},
			teams_enabled: false,
			whos_buzzer_is_active: '',
			question_num: 1,
			round_num: 1,
			question_text: '',
			answer_text: '',
		},
		on: {
			[Server_Event_Name.game_expired]: {
				actions: ['reset_context', 'clear_game_code_hash'],
			},
			[Client_Event_Name.send_feedback]: {
				actions: sendTo('websocketCallback', ({ event, context }) => {
					if (context.host_secret) {
						return add_standard_host_message_fields(event, context)
					}
					if (context.player_secret) {
						return add_standard_player_message_fields(event, context)
					}
					return event
				}),
			},
			[Server_Event_Name.feedback_received]: {
				actions: ['notify_feedback_received']
			},
			'*': {
				actions: ['event_error'],
			},
		},
		states: {
			[Client_State_Name.splash]: {
				entry: 'reset_context',
				on: {
					[Client_Event_Name.navigate_back]: {},
					[Client_Event_Name.start_player_registration]: {
						target: Client_State_Name.connecting_player,
					},
					[Client_Event_Name.start_host_registration]: {
						target: Client_State_Name.connecting_host,
					},
					[Client_Event_Name.start_host_trial_registration]: {
						actions: ['reset_context', 'set_trial_mode'],
						target: Client_State_Name.connecting_host,
					},
	
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.splash,
					},
				},
			},
			[Client_State_Name.connecting_player]: {
				entry: sendTo('websocketCallback', () => ({
					type: Websocket_Event_Names.connect_websocket,
				})),
				on: {
					[Client_Event_Name.navigate_back]: {},
					[Websocket_Event_Names.websocket_opened]: {
						target: Client_State_Name.registering_player,
					},
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.splash,
					},
					[Client_Event_Name.start_player_registration]: {},
				},
			},
			[Client_State_Name.connection_closed]: {
				on: {
					[Client_Event_Name.navigate_back]: {
						target: 'splash',
					},
					[Client_Event_Name.reset_game]: {
						target: 'splash',
					},
					[Websocket_Event_Names.connect_websocket]: {
						actions: sendTo('websocketCallback', ({event}) => event),
					},
					[Websocket_Event_Names.websocket_opened]: {
						target: Client_State_Name.reconnected,
					},
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
				},
			},
			[Client_State_Name.reconnected]: {
				entry: sendTo('websocketCallback', ({context}) => {
					if (context.host_secret) {
						return add_standard_host_message_fields(
							{type: Host_Event_Name.get_host_status},
							context,
						)
					}
					if (context.player_secret) {
						return add_standard_player_message_fields(
							{type: Client_Event_Name.player_reconnect},
							context,
						)
					}
				}),
				on: {
					[Client_Event_Name.navigate_back]: {},
					[Server_Event_Name.game_update]: {
						actions: 'update_game_state',
						target: Client_State_Name.restore_state,
					},
					[Server_Event_Name.host_update]: {
						actions: 'update_host_state',
						target: Client_State_Name.restore_state,
					},
					[Server_Event_Name.game_doesnt_exist]: {
						actions: ['reset_context', 'clear_game_code_hash'],
						target: Client_State_Name.splash,
					},
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
				},
			},
			[Client_State_Name.restore_state]: {
				always: [
					{
						guard: 'restore_host_game_unverified',
						target: Client_State_Name.host_game_unverified,
					},
					{
						guard: 'restore_host_ran_out_of_games',
						target: Client_State_Name.ran_out_of_games,
					},
					{
						guard: 'restore_host_game_setup',
						target: Client_State_Name.host_game_setup,
					},
					{
						guard: 'restore_player_game_setup',
						target: Client_State_Name.player_game_setup,
					},
					{guard: 'restore_host_in_game', target: Client_State_Name.host_in_game},
					{
						guard: 'restore_player_in_game',
						target: Client_State_Name.player_in_game,
					},
					{
						guard: 'restore_host_finished',
						target: Client_State_Name.host_finished,
					},
					{
						guard: 'restore_player_finished',
						target: Client_State_Name.player_finished,
					},
					{guard: 'no_host_or_player_secret', target: Client_State_Name.splash},
				],
				on: {
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
				},
			},
			[Client_State_Name.registering_player]: {
				on: {
					[Client_Event_Name.navigate_back]: {
						target: Client_State_Name.splash,
					},
					[Client_Event_Name.register_player]: {
						guard: 'has_player_details',
						actions: sendTo('websocketCallback', ({event}) => event),
					},
					invalid_message: {
						actions: 'publish_error',
					},
					[Server_Event_Name.player_registered]: {
						actions: 'player_registered',
						target: Client_State_Name.player_game_setup,
					},
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
					[Websocket_Event_Names.websocket_opened]: {},
				},
			},
			[Client_State_Name.player_game_setup]: {
				on: {
					[Client_Event_Name.navigate_back]: {},
					[Client_Event_Name.join_team]: {
						actions: sendTo('websocketCallback', ({event, context}) =>
							add_standard_player_message_fields(event, context),
						),
					},
					[Server_Event_Name.game_update]: {
						actions: 'update_game_state',
					},
					[Client_Event_Name.test_buzz]: {
						actions: sendTo('websocketCallback', ({event, context}) =>
							add_standard_player_message_fields(event, context),
						),
					},
					[Server_Event_Name.game_started]: {
						target: Client_State_Name.player_in_game,
					},
					[Client_Event_Name.get_player_status]: {
						actions: sendTo('websocketCallback', ({event}) => event),
					},
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
					[Server_Event_Name.game_doesnt_exist]: {
						target: Client_State_Name.splash,
					},
					[Client_Event_Name.quit]: {
						actions: sendTo('websocketCallback', ({event, context}) =>
							add_standard_player_message_fields(event, context),
						),
					},
					[Server_Event_Name.player_removed]: {
						target: Client_State_Name.splash,
					},
					[Server_Event_Name.buzzer_test]: {
						actions: 'update_test_buzzer',
					},
				},
			},
			[Client_State_Name.player_in_game]: {
				on: {
					[Client_Event_Name.navigate_back]: {},
					[Client_Event_Name.buzz]: {
						actions: sendTo('websocketCallback', ({event, context}) =>
							add_standard_player_message_fields(event, context),
						),
						target: Client_State_Name.player_in_game,
					},
					[Server_Event_Name.player_buzzed]: {
						actions: 'handle_player_buzzed',
					},
					[Server_Event_Name.game_update]: {
						actions: 'update_game_state',
					},
					[Client_Event_Name.leave_game]: {
						target: Client_State_Name.splash,
					},
					[Client_Event_Name.get_player_status]: {
						actions: sendTo('websocketCallback', ({event}) => event),
					},
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
					[Server_Event_Name.game_doesnt_exist]: {
						target: Client_State_Name.splash,
					},
					[Server_Event_Name.game_in_setup]: {
						target: Client_State_Name.player_game_setup,
					},
					[Server_Event_Name.game_finished]: {
						target: Client_State_Name.player_finished,
					},
				},
			},
			[Client_State_Name.connecting_host]: {
				entry: sendTo('websocketCallback', () => ({
					type: Websocket_Event_Names.connect_websocket,
				})),
				on: {
					[Websocket_Event_Names.websocket_opened]: {
						target: Client_State_Name.registering_host,
					},
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.splash,
					},
				},
			},
			[Client_State_Name.registering_host]: {
				on: {
					[Server_Event_Name.game_in_setup]: {
							target: Client_State_Name.host_game_setup,
					},
					[Client_Event_Name.navigate_back]: {
						target: Client_State_Name.splash,
					},
					[Host_Event_Name.register_host]: {
						guard: 'has_register_host_details',
						actions: ['set_host_email', sendTo('websocketCallback', ({event}) => event)],
					},
					[Host_Event_Name.register_trial_host]: {
						guard: 'has_register_trial_host_details',
						actions: ['set_host_nickname', sendTo('websocketCallback', ({event}) => event)],
					},
					[Server_Event_Name.request_resume_verification]: {
						target: Client_State_Name.host_resume_unverified,
					},
					[Server_Event_Name.invalid_message]: {
						actions: 'publish_error',
					},
					[Server_Event_Name.host_registered]: {
						actions: 'init_host',
					},
					[Server_Event_Name.game_setup_unverified]: {
						target: Client_State_Name.host_game_unverified,
					},
					[Host_Event_Name.get_host_status]: {
						actions: sendTo('websocketCallback', ({event}) => event),
					},
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
					[Server_Event_Name.game_doesnt_exist]: {
						target: Client_State_Name.splash,
					},
				},
			},
			[Client_State_Name.host_game_unverified]: {
				on: {
					[Client_Event_Name.verify]: send_host_message,
					[Server_Event_Name.verification_code_incorrect]: {
						actions: 'notify_verification_code_incorrect',
					},
					[Server_Event_Name.ran_out_of_games]: {
						target: Client_State_Name.ran_out_of_games,
					},
					[Server_Event_Name.game_in_setup]: {
						target: Client_State_Name.host_game_setup,
					},
					[Client_Event_Name.cancel_game]: send_host_message,
					[Server_Event_Name.game_closed]: {
						target: Client_State_Name.splash,
					},
					[Client_Event_Name.navigate_back]: {},
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
				},
			},
			[Client_State_Name.host_resume_unverified]: {
				on: {
					[Host_Event_Name.resume_game]: send_host_message,
					[Server_Event_Name.verification_code_incorrect]: {
						actions: 'notify_verification_code_incorrect',
					},
					[Server_Event_Name.host_resume_update]: [
						{
							guard: ({event}) => event.detail.state === Server_State_Name.in_play,
							actions: ['update_host_resume_state', 'update_host_state'],
							target: Client_State_Name.host_in_game,
						},
						{
							actions: ['update_host_resume_state', 'update_host_state'],
							target: Client_State_Name.host_game_setup,
						},
					],
					[Client_Event_Name.cancel_game]: {
						target: Client_State_Name.splash,
					},
					[Server_Event_Name.game_closed]: {
						target: Client_State_Name.splash,
					},
					[Client_Event_Name.navigate_back]: {},
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
				},
			},
			[Client_State_Name.ran_out_of_games]: {
				on: {
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
					[Client_Event_Name.buy_games]: send_host_message,
					[Server_Event_Name.game_in_setup]: {
						target: Client_State_Name.host_game_setup,
					},
					[Server_Event_Name.game_finished]: {
						target: Client_State_Name.splash
					},
					[Client_Event_Name.cancel_game]: send_host_message,
					[Server_Event_Name.game_closed]: {
						actions: [
							sendTo('websocketCallback', () => ({
								type: Websocket_Event_Names.client_request_disconnect,
							})),
							'reset_context',
							'clear_game_code_hash',
						],
						target: Client_State_Name.splash,
					},
				}
			},
			[Client_State_Name.host_game_setup]: {
				on: {
					[Client_Event_Name.navigate_back]: {
					},
					[Client_Event_Name.cancel_game]: send_host_message,
					[Server_Event_Name.game_finished]: {
						target: Client_State_Name.host_finished,
					},
					[Server_Event_Name.game_update]: {
						actions: 'update_game_state',
					},
					[Server_Event_Name.host_update]: {
						actions: 'update_host_state',
					},
					[Host_Event_Name.set_teams_enabled]: send_host_message,
					[Client_Event_Name.add_team]: send_host_message,
					[Host_Event_Name.remove_team]: send_host_message,
					[Host_Event_Name.remove_player]: send_host_message,
					[Host_Event_Name.save_and_exit]: send_host_message,
					[Server_Event_Name.game_saved]: {
						actions: ['reset_context', 'clear_game_code_hash'],
						target: Client_State_Name.splash,
					},
					[Host_Event_Name.start_game]: {
						guard: 'all_players_are_in_teams',
						actions: [send_host_action],
					},
					[Server_Event_Name.game_started]: {
						target: Client_State_Name.host_in_game,
					},
					[Host_Event_Name.get_host_status]: send_host_message,
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
					[Server_Event_Name.game_doesnt_exist]: {
						actions: ['reset_context', 'clear_game_code_hash'],
						target: Client_State_Name.splash,
					},
					[Server_Event_Name.buzzer_test]: {
						actions: 'update_test_buzzer',
					},
				},
			},
			[Client_State_Name.host_in_game]: {
				on: {
					[Server_Event_Name.host_update]: {
						actions: 'update_host_state',
					},
					[Host_Event_Name.get_trivia_topics]: send_host_message,
					[Server_Event_Name.send_trivia_subjects]: {
						actions: 'update_trivia_topics'
					},
					[Host_Event_Name.choose_trivia_topic]: {
						actions: [send_host_action, 'clear_trivia_topics'],
					},
					[Server_Event_Name.player_buzzed]: {
						actions: 'handle_player_buzzed',
					},
					[Client_Event_Name.clear_buzz]: send_host_message,
					[Host_Event_Name.get_host_status]: send_host_message,
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
					[Host_Event_Name.finish_round]: {
						actions: ['reset_chosen_trivia_topic', send_host_action],
					},
					[Server_Event_Name.game_doesnt_exist]: {
						target: Client_State_Name.splash,
					},
					[Host_Event_Name.finish_game]: send_host_message,
					[Server_Event_Name.game_update]: {
						actions: 'update_game_state',
					},
					[Server_Event_Name.game_finished]: {
						target: Client_State_Name.host_finished,
					},
					[Host_Event_Name.change_team_score]: send_host_message,
					[Host_Event_Name.change_player_score]: send_host_message,
					[Host_Event_Name.answer_is_correct]: send_host_message,
					[Host_Event_Name.answer_is_incorrect]: send_host_message,
					[Host_Event_Name.skip_question]: send_host_message,
					[Host_Event_Name.return_to_setup]: send_host_message,
					[Server_Event_Name.game_in_setup]: {
						target: Client_State_Name.host_game_setup,
					},
					[Client_Event_Name.navigate_back]: {
						actions: sendTo('websocketCallback', ({context}) =>
							add_standard_host_message_fields(
								{type: Host_Event_Name.return_to_setup},
								context,
							),
						),
					},
				},
			},
			[Client_State_Name.host_finished]: {
				on: {
					[Client_Event_Name.navigate_back]: {},
					[Host_Event_Name.close_game]: send_host_message,
					[Server_Event_Name.game_closed]: {
						actions: ['reset_context', 'clear_game_code_hash'],
						target: 'splash',
					},
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
				},
			},
			[Client_State_Name.player_finished]: {
				on: {
					[Client_Event_Name.navigate_back]: {},
					[Server_Event_Name.game_closed]: {
						actions: ['reset_context', 'clear_game_code_hash'],
						target: 'splash',
					},
					[Client_Event_Name.quit]: {
						actions: [
							sendTo('websocketCallback', () => ({
								type: Websocket_Event_Names.client_request_disconnect,
							})),
							'reset_context',
							'clear_game_code_hash',
						],
						target: 'splash',
					},
					[Websocket_Event_Names.websocket_closed]: {
						target: Client_State_Name.connection_closed,
					},
				},
			},
		},
	})

}


