import './Host_In_Game.css'
import { Client_Event_Name, Host_Event_Name, } from '@quiz-buzz/common'
import {page_header_style, Standard_Page_Layout, sub_header_style} from '../Components/Standard_Page_Layout'
import { Help_Tips } from '../Help_Tips/Help_tips'
import { Host_In_Game_Players_And_Teams_Content } from './Host_In_Game_Players_And_Teams_Content'
import { Host_In_Game_Questions_Content } from './Host_In_Game_Questions_Content'
import { Base_Props } from '@quiz-buzz/client-common'
import { style } from 'typestyle'
import { Colours } from '../App/style/Colours'
import { Tab, Tabs } from '../Components/Tabs'
import { Button } from '../Components/Button'

export function Host_In_Game(props: Base_Props) {
	const {
		send,
		context: {
			chosen_trivia_topic,
			round_num,
		},
	} = props

	const clear_buzz = () => send({type: Client_Event_Name.clear_buzz})
	const finish_round = () => send({type: Host_Event_Name.finish_round})
	const finish_game = () => send({type: Host_Event_Name.finish_game})
	const return_to_setup = () => send({type: Host_Event_Name.return_to_setup})

	const header = (
		<>
			<div className={page_header_style}>{!chosen_trivia_topic ? 'Choose topic' : 'Game on!'}</div>
			<div className={sub_header_style}>Round: {round_num}</div>
		</>
	)

	const content = (
		<>
			<Tabs>
				<Tab title="Questions">
					<Host_In_Game_Questions_Content context={props.context} send={send} state_value={props.state_value} />
				</Tab>
				<Tab title="Scores">
					<Host_In_Game_Players_And_Teams_Content context={props.context} send={send} state_value={props.state_value} />
				</Tab>
			</Tabs>
		</>
	)

	const controls = (
		<>
			<div className="two_buttons">
				<Button text="Clear buzzers" button_style="Tertiary" onClick={clear_buzz} />
				<Button text="Finish round" button_style="Secondary" onClick={finish_round} />
			</div>
			<div className='two_buttons'>
				<Button text="Game setup" button_style="Tertiary" onClick={return_to_setup} />
				<Button text="Finish game" button_style="Danger" onClick={finish_game} />
			</div>
			<Help_Tips id="game_start">
				<div>
					<h2>Before each round: pick a topic</h2>
					<p>
						At the start of each round you can choose a trivia topic from our list or
						you can tap 'Use my own' and the game will just keep track of the questions
						and the scores.
					</p>
					<p>
						Once you choose, you'll see the questions, players and teams.
					</p>
					
				</div>
			</Help_Tips>
		</>
	)

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}





