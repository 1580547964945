import { style } from "typestyle"
import { shiny_button_style } from "./Shiny_Buttons"

interface Button_Props {
    text: string,
    type?: "submit" | "reset" | "button",
    button_style?: 'Primary' | 'Secondary' | 'Tertiary' | 'Danger',
    fixed_width?: number,
    background_color?: string,
    form?: string,
    testId?: string,
    onClick?: () => void,
    disabled?: boolean,
    icon?: JSX.Element,
}


export const Button = ({ text, type, button_style, onClick, disabled, testId, icon, form, fixed_width, background_color }: Button_Props) => {
    
    const button_styles = [base_style, shiny_button_style]
    
    if (fixed_width) {
        button_styles.push(style({
            width: `${fixed_width}em`,
        }))
    }

    if (background_color) {
        button_styles.push(style({
            backgroundColor: background_color,
        }))
    }

    if (button_style === 'Primary') {
        button_styles.push(main_action)
    } else if (button_style === 'Danger') {
        button_styles.push(danger_action)
    }

    const Icon_Element = icon ? <div className={Icon_Style}>{icon}</div> : null

    return (<button 
        type={type} 
        disabled={disabled} 
        onClick={onClick} 
        className={button_styles.join(' ')} 
        data-testId={testId}
        form={form}>{Icon_Element} <div className={Text_Style}>{text}</div></button>)
}

const Icon_Style = style({
    margin: 'auto 0.5em',
})

const Text_Style = style({
    margin: 'auto 0',
})

const base_style = style({
    display: 'flex',
    justifyContent: 'center',
    height: '2.3em',
	margin: '0.5em 0',
	border: 'none',
	borderRadius: '0.75em',
	fontSize: '1.15em',
	fontWeight: 'bold',
	backgroundColor: 'black',
	color: 'white',
	cursor: 'pointer',
    $nest: {
        '&:disabled': { backgroundImage: 'linear-gradient(#d0d7dd 0%, #979a9c 100%)', },
    }
})

const main_action = style({
	backgroundImage: 'linear-gradient(#ff4e8c 0%, #ad2d58 100%)',
})

const danger_action = style({
	backgroundImage: 'linear-gradient(#372a2a 0%, #050404 100%)',
})

// button.smaller > span {
// 	font-size: 0.9em;
// }

// button.mini_text {
// 	height: auto;
// 	padding: 0.3em;
// }

// button.inline {
// 	float: right;
// }

// button.tiny {
// 	font-size: 0.7em;
// }

// button.mini_text span {
// 	font-size: 0.75em;
// }

// button.icon_button {
// 	display: flex;
// 	padding: 0 0.2em;
// }

// button.icon_button > * {
// 	margin: auto 0.3em;
// }

// button.icon_button > svg {
// 	height: 2rem;
// 	width: 3rem;
// }
// button.icon_button > div {
// 	font-size: 1rem;
// }

// button.input_attach {
// 	border-radius: 0 0.3em 0.3em 0;
// 	height: 1.9em;
// 	margin: auto 0;
// 	min-width: 2em;
// 	border: 1px solid black;
// }