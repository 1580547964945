import { style } from "typestyle"
import Carousel, { ArrowProps } from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

export function Splash_Main_Content() {

	const responsive = {
		superLargeDesktop: {
		  // the naming can be any, depends on you.
		  breakpoint: { max: 4000, min: 3000 },
		  items: 1
		},
		desktop: {
		  breakpoint: { max: 3000, min: 1024 },
		  items: 1
		},
		tablet: {
		  breakpoint: { max: 1024, min: 464 },
		  items: 1
		},
		mobile: {
		  breakpoint: { max: 464, min: 0 },
		  items: 1
		}
	  };


	const CustomRightArrow = ({ onClick }: ArrowProps) => {
		// onMove means if dragging or swiping in progress.
		return <button className={carousel_right_arrow_style} onClick={onClick}><span className={triangle_right_tyle}></span></button>;
	};
	
	const CustomLeftArrow = ({ onClick }: ArrowProps) => {
		// onMove means if dragging or swiping in progress.
		return <button className={carousel_left_arrow_style} onClick={onClick}><span className={triangle_left_tyle}></span></button>;
	};
	  



    return <div className={splash_vertical_style}>
		<Carousel 
			customLeftArrow={<CustomLeftArrow />}	
			customRightArrow={<CustomRightArrow />}
			swipeable={true}
			draggable={true}
			className={carousel_style} 
			sliderClass={carousel_slider_style}
			responsive={responsive} 
			arrows={true}>
			<div className={carousel_item_style}>
				<span className={splash_content_header}>Welcome, quiz hosts!</span>
				<span className={splash_content_body}>
					With Quiz Buzz you can host a quiz show where your players can use their phones as a buzzer
					<br /><br />
				</span>
			</div>
			<div className={carousel_item_style}>
				<span className={splash_content_header}>Loads of topics to choose from in game</span>
				<span className={splash_content_body}>Pick a new topic each round and get a selection of questions from our library</span>
			</div>
			<div className={carousel_item_style}>
				<span className={splash_content_header}>Or use your own questions</span>
				<span className={splash_content_body}>You can pick 'use my own questions' for any round and read your questions from anywhere</span>
			</div>
			<div className={carousel_item_style}>
				<span className={splash_content_header}>Play with teams or individuals</span>
				<span className={splash_content_body}>Change your game settings any time during the game</span>
			</div>
			<div className={carousel_item_style}>
				<span className={splash_content_header}>Play online or in person, or mixed</span>
				<span className={splash_content_body}>Players can be anywhere in the world, including right in front of you</span>
			</div>
			<div className={carousel_item_style}>
				<span className={splash_content_header}>Games are free up to 15 minutes</span>
				<span className={splash_content_body}>
					If you want to get more serious and see the extra features, you can pay around 30 cents per game
					<br /><br />
					Full game features include:
					<ul>
						<li>Unlimited time</li>
						<li>Music rounds</li>
						<li>Picture rounds</li>
						<li>Multiple choice</li>
						<li>Save and resume</li>
						<li>Plus many more coming!</li>
					</ul>
				</span>
			</div>
			<div className={carousel_item_style}>
				<span className={splash_content_header}>We dont collect, share or sell your personal info</span>
				<span className={splash_content_body}>We&apos;re serious about privacy.<br /><br />
					<ul>
						<li>No tracking</li>
						<li>No cookies</li>
						<li>No personal info</li>
						<li>No email spam</li>
					</ul><br /><br />
					contact support@quiz-buzz.com for more info
				</span>
			</div>
		</Carousel>
	</div>
}

const splash_content_header = style({
	fontSize: '2.5em',
	marginBottom: '0.5em',
})

const splash_content_body = style({
	fontSize: '1.15em',
})

const splash_vertical_style = style({
    display: 'flex',
    flexDirection: 'column',
	overflow: 'hidden',
	width: '100%',
	flex: 1,
})

const carousel_style = style({
	display: 'flex',
	flex: 1,
	padding: '0.35rem 0',
})

const carousel_slider_style = style({
	height: '100%'
})

const carousel_item_style = style({
	padding: '10% 1em 1em 1em',
    display: 'flex',
    height: '100%',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	background: 'rgba(255, 255, 255, 0.2)',
	borderRadius: '2em',
	backdropFilter: 'blur(4.1px)',
	border: '1px solid rgba(255, 255, 255, 0.3)',
	margin: '0 0.35rem',
})

const carousel_right_arrow_style = style({
	width: '2.7em',
	backgroundColor: '#aaa5',
	height: '4em',
	position: 'absolute',
	right: '0',
	borderRadius: '2em 0 0 2em',
	border: 'none',
	paddingLeft: '0.75em',
})

const triangle_right_tyle = style({
	display: 'inline-block',
	width: '0',
	height: '0',
	borderTop: '0.8em solid transparent',
	borderBottom: '0.8em solid transparent',
	borderLeft: '0.8em solid white'
})

const carousel_left_arrow_style = style({
	width: '2.7em',
	backgroundColor: '#aaa5',
	height: '4em',
	position: 'absolute',
	left: '0',
	borderRadius: '0 2em 2em 0',
	border: 'none',
	paddingRight: '0.75em',
})

const triangle_left_tyle = style({
	display: 'inline-block',
	width: '0',
	height: '0',
	borderTop: '0.8em solid transparent',
	borderBottom: '0.8em solid transparent',
	borderRight: '0.8em solid white'
})
