import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { useActor } from '@xstate/react'
import { get_player_machine } from '@quiz-buzz/client-common'
import toast from 'react-hot-toast'
import { Key_For_XState_Persistence } from './constants.ts'
import { set_environment } from '@quiz-buzz/client-common/src/Config/Client_Environments.ts'

const storage_snapshot_string = sessionStorage.getItem(
	Key_For_XState_Persistence,
)
const storage_snapshot = storage_snapshot_string
	? JSON.parse(storage_snapshot_string)
	: undefined

const toast_impl = {
	info(message: string) {
		toast(message)
	},
	error(message: string) {
		toast.error(message)
	},
}

const Env_Urls: { [index: string]: string } = {
    'http://192.168.0.2:3000': 'localdev',
    'http://localhost:3000': 'localdev',
    'http://localhost:3001': 'autotest',
    'https://quiz-buzz.com': 'production',
}

// function determine_environment_for_web() {
    // Determine which environment we are in using the URL:
const loc = window.location.protocol + "//" + window.location.hostname + (window.location.port ? `:${window.location.port}` : '')
const env_name = Env_Urls[loc]
if (!env_name) {
	throw new Error('no environment found for URL: ' + loc)
}
set_environment(env_name)
// }



const client_state_machine = get_player_machine( toast_impl )

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<App client_state_machine={client_state_machine} snapshot={storage_snapshot} />
	</React.StrictMode>,
)
