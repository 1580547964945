import { Team } from "@quiz-buzz/common"
import { style } from "typestyle"
import { Button } from "./Button"

interface Team_And_Members_Box_Props {
    team: Team,
    is_host: boolean,
    primary_action: () => void
}

export const Team_And_Members_Box = (props: Team_And_Members_Box_Props) => {
	const {team, is_host } = props
    
    return (
        <div className={team_container_style} data-testid={`team_container_${team.name}`}>
            <div className={team_container_left_style}>
                <div className={team_name_style}>{team.name}</div>
                {is_host ? <button
                        className={team_remove_button_style}
                        data-testid={`remove team ${team.name}`}
                        title={`remove team ${team.name}`}
                        onClick={props.primary_action}>
                            <span>
                               remove
                            </span></button>
                        :
                        <Button
                            button_style="Tertiary"
                            text="join"
                            data-testid={`join team ${team.name}`}
                            // title={`join team ${team.name}`}
                            onClick={props.primary_action} />
                        }
            </div>
            <div className={team_right_side_style}>
                <div>Team members:</div>
                <div>
                    <span className="small-text">{team.members.list.length === 0 && 'waiting for players to join team..'}</span>
                    {team.members.list.map(member => (
                        <div className={player_item_style} data-player-item={member.name}>
                            {member.name}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export const team_container_style = style({
    display: 'flex',
    minHeight: '8em',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    marginBottom: '0.2em',
    marginTop: '0.2em',
    borderRadius: '0.3em',
})

const team_container_left_style = style({
	display: 'flex',
	flexDirection: 'column',
    backgroundColor: '#00000033',
    marginRight: '0.3em',
    position: 'relative',
})

const team_remove_button_style = style({
    width: '100%',
    position: 'absolute',
    bottom: '0',
    borderRadius: '0 0 0 0.3em',
    border: 'none',
    height: '2em',
    background: 'linear-gradient(180deg, #8889 0%, #a88d 100%)',
    color: 'black',
})

export const team_name_style = style({
	margin: '0.5em 0.2em 0.2em 0.2em',
	fontSize: '1.2em',
	width: '5em',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
})

export const team_right_side_style = style({
	display: 'flex',
	flexDirection: 'column',
	flex: '1',
})

export const team_players_box_style = style({
	padding: '0.3em 0',
	flex: '1',
})

export const player_item_style = style({
	display: 'inline-block',
	borderRadius: '0.75em',
	fontSize: '1.15em',
	fontWeight: '700',
	backgroundColor: '#1f3570',
	color: '#fff',
	padding: '0.1em 1em',
	margin: '0.1em 0.2em',
	maxWidth: '7em',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	height: '1.3em',
})