import { Client_Event_Name, } from '@quiz-buzz/common'
import {page_header_style, Standard_Page_Layout, sub_header_style} from '../Components/Standard_Page_Layout'
import { Base_Props, Game_Results, get_game_results } from '@quiz-buzz/client-common'
import { style } from 'typestyle'
import { Button } from '../Components/Button'

export function Player_Finished(props: Base_Props) {
	const {teams_enabled} = props.context
	const {send} = props

	const send_quit = () => send({type: Client_Event_Name.quit})

	const results = get_game_results(props.context)

	const generate_results_content = (results: Game_Results) => {
		if (teams_enabled) {
			if (results.is_draw) {
				return (
					<>
						<div className={results_box_style}>
							<div className={results_box_header_style}>It's a draw!</div>
							<div className={results_box_winners_style}>
								Congratulations to{' '}
								<div className={winner_list_style}>
									{results.team_winners.map(team => team.name).join(', ')}
								</div>
							</div>
						</div>
					</>
				)
			} else {
				return (
					<>
						<div className={results_box_style}>
							<div className={results_box_header_style}>We have a winner!</div>
							<div className={results_box_winners_style}>
								Congratulations to{' '}
								<div className={winner_list_style}>
									{results.team_winners[0].name}
								</div>
							</div>
						</div>
					</>
				)
			}
		} else {
			if (results.is_draw) {
				return (
					<>
						<div className="results_box">
							<div className={results_box_header_style}>It's a draw!</div>
							<div className={results_box_winners_style}>
								Congratulations to{' '}
								<div className={winner_list_style}>
									{results.player_winners.map(player => player.name).join(', ')}
								</div>
							</div>
						</div>
					</>
				)
			} else {
				return (
					<>
						<div className="results_box">
							<div className={results_box_header_style}>We have a winner!</div>
							<div className={results_box_winners_style}>
								Congratulations to{' '}
								<div className={winner_list_style}>
									{results.player_winners[0].name}
								</div>
							</div>
						</div>
					</>
				)
			}
		}
	}

	const header = (
		<>
			<div className={page_header_style}>Finished</div>
			<div className={sub_header_style}>
				congratulations to the winner!
			</div>
		</>
	)
	const content = generate_results_content(results)

	const controls = (
		<div className='two_buttons'>
			<Button text="Exit" button_style="Primary" onClick={send_quit} />
		</div>
	)
	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}

const results_box_style = style({
	margin: 'auto',
	transform: 'rotate(-8deg)',
	textAlign: 'center',
})

const results_box_header_style = style({
	fontFamily: '"Titan One", sans-serif',
	fontSize: '3rem',
})

const results_box_winners_style = style({
})

const winner_list_style = style({
	fontSize: '2em',
	backgroundImage: 'linear-gradient(#dacc34, #9b8722)',
})
