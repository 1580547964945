import { Client_Event_Name, } from '@quiz-buzz/common'
import {page_header_style, Standard_Page_Layout, sub_header_style} from '../Components/Standard_Page_Layout'
import { Base_Props } from '@quiz-buzz/client-common'
import { style } from 'typestyle'
import { Colours } from '../App/style/Colours'

export function Player_In_Game(props: Base_Props) {
	const {
		send,
		context: {
			teams,
			players,
			teams_enabled,
			player_name,
			player_secret,
			whos_buzzer_is_active,
		},
	} = props

	const buzz = () =>
		send({
			type: Client_Event_Name.buzz,
			detail: {player_name, player_secret},
		})
	const buzzer_disabled =
		!!whos_buzzer_is_active && player_name !== whos_buzzer_is_active
	const buzzer_pressed = player_name === whos_buzzer_is_active

	const header = (
		<>
			<div className={page_header_style}>Game on!</div>
			<div className={sub_header_style}>
				player: {player_name}
			</div>
		</>
	)
	const content = (
		<>
			<div className={buzzer_container_style}>
				<button
					data-testId="buzzer"
					className={`${buzzer_style} ${buzzer_pressed ? buzzer_active_style : ''}`}
					disabled={buzzer_disabled}
					onClick={buzz}></button>
			</div>
		</>
	)

	const controls = (
		<>
			{teams_enabled ? (
				<div className={scoreboard_style}>
					{teams.list.map(team => (
						<>
							<div className={score_entry_style}>
								<div className={score_header_style}>{team.name}</div>
								<div className={score_value_style}>{team.score}</div>
							</div>
						</>
					))}
				</div>
			) : (
				<div className={scoreboard_style}>
					{players.list.map(player => (
						<>
							<div className={score_entry_style}>
								<div className={score_header_style}>{player.name}</div>
								<div className={score_value_style}>{player.score}</div>
							</div>
						</>
					))}
				</div>
			)}
		</>
	)
	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}

const buzzer_container_style = style({
	width: '100%',
	height: '100vw',
	maxWidth: '30rem',
	maxHeight: '30rem',
})

const buzzer_style = style({
	width: '100%',
	height: '100%',
	borderRadius: '50%',
	marginTop: '1em',
	border: `0.5em solid ${Colours.primary_highlight}`,
	backgroundImage: 'radial-gradient(#19080e, black)',
	$nest: {
		'&:disabled': {
			backgroundImage: 'radial-gradient(#a4a4a4, #4e4e4e)',
		}
	  }
})

const buzzer_active_style = style({
	border: '0.5em solid white',
	backgroundImage: Colours.neon_radial_gradient,
})

const scoreboard_style = style({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-evenly',
})

const score_entry_style = style({
	display: 'flex',
	flexDirection: 'column',
	margin: '1em',
	border: '1px solid white',
	padding: '1em',
	flex: '1',
})

const score_header_style = style({
	fontSize: '2em',
})

const score_value_style = style({
	fontFamily: '"Titan One"',
	fontSize: '2em',
})
