import { style } from "typestyle";

export const header_over_form_style = style({
	display: 'flex',
	flexDirection: 'column',
	border: '1px solid #ad2d58',
	borderRadius: '0.4em 0.4em 0 0',
	textAlign: 'center',
	margin: '0',
	backgroundColor: 'white',
	padding: '0.3em',
})